<template>

     <div class="flex flex-col mt-11" v-for="(tutor, index) in tutors" :key="index">
          <TutorCard
            :imgUrl="tutor.user.img_url"
            :classes="tutor.courses"
            :tutorName="tutor.user.name"
            :bio="tutor.user.bio"
            :rate="tutor.rate"
            :rating="tutorCourseMeta(tutor).rating"
            :num_reviews="tutorCourseMeta(tutor).num_sessions"
            :student_imgs="tutorCourseMeta(tutor).student_imgs"
            :username="tutor.user.username"
          />
        </div>
   
</template>

<script>
import { mapGetters } from "vuex";
import BurgerMenu from "../components/Feed/BurgerMenu.vue";
import HMenu from "../components/HMenu.vue";
import TutorCard from "../components/TutorCard.vue";
import { getTutors, getUniversityDepartments } from "../helpers/api";
    export default {
        components: {HMenu,TutorCard},
        data() {
    return {
      codes: [],
      user:null,
      tutors: null,
      total: null,
      page: 1,
      limit: 15,
      loading: false,
      univeristy: null,
      department: null,
      tutorsLoading: true,
      depts: null,
      sort: null,
     
    };
  },
  computed:{
    ...mapGetters(["getTutorCourseMeta"]),
  },
  created(){
   this.setUser();
   this.loadTutors();
  },
  methods:{
     tutorCourseMeta(tutor) {
      return this.getTutorCourseMeta(tutor);
    },
    setUser() {
      this.user = this.$store.state.user;

      // splitting name into firstname and lastname
      const name = this.user.name.split(" ");
      this.firstname = name[0];
      name.shift();
      this.lastname = name.join(" ");
      this.lastname = this.lastname.replace(/\s+/g, " ").trim();
     
      this.university = this.user.university.abbrev;
      this.classof = this.user.classOf;
      this.img_url = this.user.img_url;
      this.bio= this.user.bio;
      this.updateImage++;
     
      this.codes =this.user.classes;
    },
     async loadTutors() {
      const { tutors, number } = await getTutors({
        limit: this.limit,
        page: 1,
        university: this.university,
        department: this.department,
        sort: this.sort,
      });
      this.total = number;
      this.page = 2;
     this.tutors=tutors;
    },
  }
    }
</script>
